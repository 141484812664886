<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="bg-white  full-height overflow-y-auto"
		>

			<div class="full-width ">

				<Search
					:search="item_search"
					:option="search_option"

					@change="getData"
					@click="getData"
					@toExcel="toExcel"
					class="mt-10"
				>

					<select
						slot="add"
						v-model="item_search.virtual_uid"
						class="mr-10 input-box-inline size-px-12"
						@change="getSearch(1)"
					>
						<option value="">지갑 구분</option>
						<option
							v-for="(virtual, v_index) in items_virtual"
							:key="'virtual_' + v_index"
							:value="virtual.uid"
						>{{ virtual.virtual_name }}</option>
					</select>
					<select
						slot="add"
						v-model="item_search.send_type"
						class="pa-5-10 mr-10"

						@change="getSearch(1)"
					>
						<option value="">전송 구분</option>
						<template
							v-for="(code, index) in codes.W001.items"
						>
							<option
								:key="code.total_code + '_' + index"
								:value="code.code_value"
							>{{ code.code_name }}</option>
						</template>
					</select>
					<select
						slot="add"
						v-model="item_search.history_type"
						class="pa-5-10 mr-10"

						@change="getSearch(1)"
					>
						<option value="">입출금 구분</option>
						<option value="0">출금</option>
						<option value="1">입금</option>
					</select>
					<select
						slot="add"
						v-model="item_search.status"
						class="pa-5-10 mr-10"

						@change="getSearch(1)"
					>
						<option value="">승인 상태</option>
						<template
							v-for="(code, index) in codes.U001.items"
						>
							<option
								:key="code.total_code + '_' + index"
								:value="code.code_index"
							>{{ code.code_name }}</option>
						</template>
					</select>
				</Search>

				<div
					v-if="items.length > 0"
				>
					<table
						class="mt-10 table table-even"
					>
						<colgroup>
							<col width="80px" />
						</colgroup>
						<thead>
						<tr>
							<th>
								<input
									type="checkbox"
								/>
							</th>
							<th>소속</th>
							<th>구분</th>
							<th>아이디</th>
							<th>상호명</th>
							<th>지갑 구분</th>

							<th>전송 구분</th>
							<th>입출금 구분</th>
							<th>승인 상태</th>
							<th>금액</th>
							<th>수수료</th>
							<th>등록일</th>
							<th>상세정보</th>
						</tr>
						</thead>
						<tbody
							v-if="items.length > 0"
						>
						<tr
							v-for="item in list_history"
							:key="item.uid"
						>
							<td>{{ item.uid }}</td>
							<td>{{ item.upper_name }}</td>
							<td>{{ item.agency_type_name }}</td>
							<td>{{ item.account_id }}</td>
							<td>{{ item.agency_name }}</td>
							<td>{{ item.virtual_name }}</td>

							<td>{{ item.send_type_name }}</td>
							<td :class="'color-' + item.history_type_color">{{ item.history_type_name }}</td>
							<td :class="'color-' + item.status_color">{{ item.status_name }}</td>
							<td :class="'color-' + item.history_type_color">{{ item.amount | makeComma }}원</td>
							<td>{{ item.fee | makeComma }}원</td>
							<td>{{ item.wDate }}</td>
							<td>
								<button
									class="bg-identify pa-5-10"
									@click="onDetail(item)"
								>상세정보</button>
							</td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="item_search"

						class="mt-auto"
						@click="getSearch"
					></Pagination>
				</div>
				<Empty
					v-else
				></Empty>
			</div>
		</div>
		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>

		<AgencyWalletDetail
			v-if="is_on_detail"
			:item="item_detail"

			@cancel="is_on_detail = false"
			@click="is_on_detail = false"
		></AgencyWalletDetail>
	</div>
</template>

<script>

import Search from "../Layout/Search";
import Pagination from "../../components/Pagination";
import Empty from "../Layout/Empty";
import Excel from "@/components/Excel";
import AgencyWalletDetail from "./AgencyWalletDetail";

export default {
	name: 'AgencyWalletHistory'
	, props: ['user', 'codes', 'virtual_uid', 'date']
	, components: {AgencyWalletDetail, Excel, Empty, Search, Pagination}
	, data: function(){
		return {
			program: {
				name: '영업점 입출금 내역'
				, top: true
				, title: true
				, bottom: false
			}
			, items: []
			, item_search: {
				virtual_uid: this.$route.params.idx
				, virtual_status: ''
				, virtual_able: ''
				, list_cnt: 10
				, search_type: 'account_agency.agency_id'
				, is_use: ''
				, total_count: 0
				, page: 1
				, tCnt: 0
				, send_type: ''
				, history_type: ''
				, sDate: this.$date.getLastDate(7, '-')
				, eDate: this.$date.getToday('-')
				, status: ''
			}
			,search_option:{

				is_cnt: true
				, is_excel: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: 'account_agency.agency_id'}
					, { name: '상호명', column: 'shop_name'}
				]
				,select: [
				]
				, sDate: true
				, eDate: true
			}
			, is_excel: false
			,excel_data: {
				name: '영업점 입출금 내역'
				,header: [
					{ key: 0, name: '소속', column: 'agency_upper_name'}
					,{ key: 0, name: '구분', column: 'agency_type_name'}
					,{ key: 0, name: '아이디', column: 'account_id'}
					,{ key: 0, name: '상호명', column: 'agency_name'}
					,{ key: 0, name: '지갑구분', column: 'virtual_name'}
					,{ key: 0, name: '전송구분', column: 'send_type_name'}
					,{ key: 0, name: '입출금 구분', column: 'history_type_name'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '수수료', column: 'fee'}
					,{ key: 0, name: 'TID', column: 'tid'}
					,{ key: 0, name: '메모', column: 'memo'}
					,{ key: 0, name: '등록일', column: 'wDate'}
				]
				,content: null
			}
			, is_on_detail: false
			, item_detail: {}
			, items_virtual: []
		}
	}
	, computed: {
		list_history: function(){
			return this.items.filter( ( item ) => {

				switch (item.history_type){
					case '0':
						item.history_type_name = '출금'
						item.history_type_color = 'red'
						item.amount *= -1
						break;
					case '1':
						item.history_type_name = '입금'
						item.history_type_color = 'blue'
						break;
				}
				this.codes["U001"].items.filter( (status) => {
					if(item.status == status.code_index){

						item.status_name = status.code_name
						item.status_color = status.code_color
					}
				})
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyWalletHistory'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getData()
		}
		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		, onDetail: function(item){
			this.item_detail = item
			this.is_on_detail = true
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
					}
				})
				if (result.success) {
					this.items_virtual = result.data
					this.item_search.virtual_uid = this.items_virtual[0].uid
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getVirtualList()
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>